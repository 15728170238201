import { useRef } from "react";
import type { Editor } from "@tiptap/react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  DIRECT_CHAT_ROOM,
  GROUP_CHAT_ROOM,
} from "@/react/components/constants";
import { TiptapEditor } from "@circle-react/components/shared/uikit/TipTap";
import { usePunditUserContext } from "@circle-react/contexts";
import { isMobileOs } from "@circle-react/helpers/browserHelpers";
import { useChatRoom } from "@circle-react/hooks/chatsV2";
import { useChatsV2Api } from "@circle-react/hooks/chatsV2/useChatsV2Api";
import { useTypingIndicator } from "@circle-react/hooks/chatsV2/useTypingIndicator";
import {
  isChatRoomAdminOrModerator,
  isDirectChatRoom,
} from "../ChatWindowV2/helpers";
import { TypingIndicator } from "../TypingIndicator";

export interface RichTextMessageBoxProps {
  onClick?: () => void;
  autoFocus?: boolean;
  disabled?: boolean;
  spaceId?: number;
  chatRoom?: any;
  isChatPreview?: boolean;
  parentMessageId?: number;
  userList?: any[];
  isLiveStream?: boolean;
  isChatSpace?: boolean;
  isInCourseSpace?: boolean;
  portalTargetId?: string;
  isLoading?: boolean;
  participants?: any[];
}

interface Params {
  uuid: string;
}

export const RichTextMessageBox = ({
  onClick,
  autoFocus = false,
  disabled = false,
  spaceId,
  chatRoom,
  isChatPreview,
  parentMessageId,
  userList = [],
  isLiveStream = false,
  isChatSpace = false,
  isInCourseSpace = false,
  portalTargetId = "chat-threads-header",
  isLoading = false,
  participants = [], //
}: RichTextMessageBoxProps) => {
  const { uuid: chatRoomUuid } = useParams<Params>();
  const { currentCommunityMember }: any = usePunditUserContext();
  const { sendRichTextMessage }: any = useChatsV2Api();
  const history: any = useHistory();

  const { findOrCreateChatRoom } = useChatRoom();
  const editorRef = useRef<Editor>(null);

  const createChatRoom = async ({ richTextBody }: any) => {
    const roomKind = userList.length > 1 ? GROUP_CHAT_ROOM : DIRECT_CHAT_ROOM;
    await findOrCreateChatRoom({
      kind: roomKind,
      community_member_ids: userList.map(({ id }) => id),
      rich_text_body: richTextBody,
      shouldNavigate: true,
    });
  };

  isMobileOs() && (autoFocus = false);

  const isDirectMessaging = isDirectChatRoom(chatRoom);
  const isChatAdminOrModerator = isChatRoomAdminOrModerator(chatRoom);
  const { isViewOnlyMasquerading } = usePunditUserContext();
  const { current_participant: currentParticipant = {} } = chatRoom || {};
  const { isTyping, typingUserNames } = useTypingIndicator({
    enabled: !isInCourseSpace,
    chatRoomUuid: chatRoom?.uuid,
    currentParticipant: currentParticipant,
    editorRef,
    participants: participants,
    parentMessageId: parentMessageId,
  });

  return (
    <div className="bg-primary relative px-5 pt-2">
      <TiptapEditor
        autofocus={autoFocus}
        disabled={disabled || isViewOnlyMasquerading}
        onSubmit={({
          body,
          attachments,
          localAttachments,
          localSgidToObjectMap,
        }: any) => {
          if (disabled) {
            return;
          }

          onClick?.();

          if (chatRoom) {
            sendRichTextMessage({
              chatRoom,
              rich_text_body: { body, attachments },
              localAttachments,
              currentCommunityMember,
              localSgidToObjectMap,
              parentMessageId,
            });
            if (isChatPreview && (chatRoomUuid == "new" || !chatRoomUuid)) {
              history.push(`/messages/${chatRoom.uuid}`);
            }
          } else {
            void createChatRoom({
              richTextBody: { body, attachments },
            });
          }
        }}
        spaceId={spaceId}
        id="tiptapMessageTextBox"
        editorRef={editorRef}
        portalTargetId={portalTargetId}
        isLiveStream={isLiveStream}
        isChatSpace={isChatSpace}
        shouldShowTextModifiersMenu={!isLoading}
        chatProps={{
          disableGroupMentions: isLiveStream || isInCourseSpace,
          isThread: !!parentMessageId,
          isDirectMessaging: isDirectMessaging,
          isChatAdminOrModerator: isChatAdminOrModerator,
          chatParticipantsCount: chatRoom?.chat_room_participants_count,
          isTyping,
        }}
      />
      {isTyping && <TypingIndicator typingUserNames={typingUserNames} />}
    </div>
  );
};
